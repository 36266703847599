<template>
  <div class="container" id="content">
    <div class="title">
      <div class="title-top">
        <div class="el-title-item">
          <el-form ref="form" label-width="80px">
            <el-form-item label="">
              <el-button type="success" size="small" @click="addOption">新增</el-button>
            </el-form-item>
            <el-form-item label="" style="margin-left: 2vw">
              <el-select
                  v-model="searchName"
                  placeholder="请选择住户类型"
                  @change="typeChange"
              >
                <el-option
                    v-for="item in typeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <el-button style="margin-left: 1vw;" type="success" size="small" @click="search">搜索</el-button>
      </div>
    </div>
    <el-table
        v-loading="loading"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 1vh;">
      <el-table-column width="100" :index="indexMethod" label="序号" type="index" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="villageId" label="乡村名称" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <span>{{getName1(scope.row.villageId)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="gridManagementId" label="网格名称" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <span>{{getName2(scope.row.gridManagementId)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="householder" label="户主" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="address" label="地址" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="familySize" label="家庭人数" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="type" label="住户类型" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.type == 0">定居</span>
          <span v-if="scope.row.type == 1">租户</span>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="家庭信息备注" show-overflow-tooltip align="center"></el-table-column>
      <!--      <el-table-column prop="longitude" label="经度" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="latitude" label="纬度" show-overflow-tooltip align="center"></el-table-column>-->
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <!-- <el-button
            type="primary"
            size="small"
            icon="el-icon-document"
            @click="details(scope.row)"
          ></el-button> -->
          <el-button
              type="primary"
              size="small"
              icon="el-icon-edit"
              @click="edits(scope.row)"
          ></el-button>
          <el-button
              type="primary"
              size="small"
              icon="el-icon-delete"
              @click="deletes(scope.row.id)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block pagingBox">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </div>
    <!--    编辑-->
    <el-dialog title="" :visible.sync="messageBox" width="31vw" class="addAlameBox">
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ formTitle }}</span>
        <span style=" position: absolute; top: 50%; transform: translateY(-50%); right: 0px; font-size: 2.22vh;"></span>
      </div>
      <div class="addAlaForm">
        <el-form ref="addForm" :rules="rules" :model="addForm" label-width="10vw">
          <el-form-item label="乡村名称" prop="villageId">
            <el-select
                v-model="addForm.villageId"
                placeholder="请选择乡村"
            >
              <el-option
                  v-for="item in nameList"
                  :key="item.id"
                  :label="item.villageName"
                  :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="网格名称" prop="gridManagementId">
            <el-select
                v-model="addForm.gridManagementId"
                placeholder="请选择网格"
            >
              <el-option
                  v-for="item in gridList"
                  :key="item.id"
                  :label="item.gridName"
                  :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="户主" prop="householder">
            <el-input v-model="addForm.householder" placeholder="请输入户主"></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="address">
            <el-input v-model="addForm.address" placeholder="请输入地址"></el-input>
          </el-form-item>
          <el-form-item label="家庭人数" prop="familySize">
            <el-input type="number" v-model="addForm.familySize" placeholder="请输入家庭人数"></el-input>
          </el-form-item>
          <el-form-item label="住户类型" prop="type">
            <el-select
                v-model="addForm.type"
                placeholder="请选择住户类型"
            >
              <el-option
                  v-for="item in typeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="家庭信息备注" prop="remark">
            <el-input  v-model="addForm.remark" placeholder="请输入家庭信息备注"></el-input>
          </el-form-item>
          <el-form-item label="经纬度" prop="data7">
            <el-input v-model="addForm.data7" placeholder="请输入经纬度"></el-input>
          </el-form-item>
          <set-Map ref="mapPosition" @getDatas="getDatas"></set-Map>
        </el-form>
        <div slot="footer" class="dialog-footer" v-show="showFlag">
          <el-button type="info" @click="messageBox = false">取消</el-button>
          <el-button type="primary" @click="saveForm('addForm')">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import setMap from "../../../components/setMap.vue";
export default {
  components: { setMap },
  name: 'sensorManagement',
  data() {
    const checkPosition = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("不可为空"));
      }
      var lon = /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,6})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,6}|180)$/;
      var lat = /^(\-|\+)?([0-8]?\d{1}\.\d{0,6}|90\.0{0,6}|[0-8]?\d{1}|90)$/;
      var lonRe = new RegExp(lon);
      var latRe = new RegExp(lat);
      let lon1 = value.split(",")[0];
      let lat1 = value.split(",")[1];
      setTimeout(() => {
        if (lon1 == '' || !lonRe.test(value.split(",")[0])) {
          callback(new Error("经度不符合规范：经度整数部分为0-180,小数部分为0-6位！"));
        } else if (lat1 == '' || !latRe.test(value.split(",")[1])) {
          callback(new Error("纬度不符合规范：纬度整数部分为0-90,小数部分为0-6位！"));
        } else {
          callback();
        }
      }, 100);
    };
    return {
      tableData: [],
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      ids: 0,
      searchName: '',
      time1: '',
      addForm: {},
      data7: '',
      rules: {
        villageId: [
          {required: true, message: "乡村名称不可为空", trigger: ["blur", "change"]},
        ],
        gridManagementId: [
          {required: true, message: "网格名称不可为空", trigger: ["blur", "change"]},
        ],
        householder: [
          {required: true, message: "户主不可为空", trigger: ["blur", "change"]},
        ],
        type: [
          {required: true, message: "住户类型不可为空", trigger: ["blur", "change"]},
        ],
        tel: [
          { required: true, message: "联系方式不可为空", trigger: "change" },
          {
            validator: function (rule, value, callback) {
              if (/^1[34578]\d{9}$/.test(value) == false) {
                callback(new Error("请输入正确的手机号"));
              } else {
                callback();
              }
            },
            trigger: "change",
          },
        ],
        address: [
          {required: true, message: "地址不可为空", trigger: ["blur", "change"]},
        ],
        data7: [{ required: true, validator: checkPosition, trigger: "change" }],
        longitude: [
          {required: true, message: "经纬度不可为空", trigger: ["blur", "change"]},
        ],
      },
      formTitle: "",
      messageBox: false,
      showFlag: false,
      value: true,
      typeList: [
        { id: 1, name: '农事服务中心'},
        { id: 2, name: '服务立站点'},
      ],
      gridList: [],
      nameList: [],
      typeList: [
        { id: 0, name: '定居' },
        { id: 1, name: '租户' },
      ]
    };
  },
  mounted() {
    this.getVillageId();
    this.getGridId();
    this.getList();
  },
  methods: {
    indexMethod(index) {
      return index + this.size * (this.page - 1) + 1;
    },
    typeChange(){
      this.getList();
    },
    getName1(val){
      for(let a = 0; a < this.nameList.length; a++){
        if(this.nameList[a].id == val){
          return this.nameList[a].villageName;
        }
      }
    },
    getName2(val){
      for(let a = 0; a < this.gridList.length; a++){
        if(this.gridList[a].id == val){
          return this.gridList[a].gridName;
        }
      }
    },
    //请求列表数据
    getList() {
      this.$get("/villagerInformation/getVillagerInformations", {
        page: this.page,
        size: this.size,
        type: this.searchName,
        villageId: '',
        householder: '',
        gridManagementId: '',
      }).then((res) => {
        if (res.data.state == "success") {
          this.tableData = res.data.datas;
          this.total = res.data.count;
          //console.log("获取列表", this.tableData)
        }
      });
    },
    // 获取乡村
    getVillageId(){
      this.$get("/villageIntroduction/getVillageIntroductions", {
        page: this.page,
        size: 1000,
      }).then((res) => {
        if (res.data.state == "success") {
          this.nameList = res.data.datas;
        }
      });
    },
    // 获取网络
    getGridId(){
      this.$get("/gridManagement/getGridManagements", {
        page: this.page,
        size: 1000,
      }).then((res) => {
        if (res.data.state == "success") {
          this.gridList = res.data.datas;
        }
      });
    },
    // 编辑
    edits(row) {
      this.showFlag = true;
      this.ids = row.id;
      this.formTitle = "编辑";
      this.messageBox = true;
      setTimeout(() => {
        this.$refs["addForm"].clearValidate();
        this.$set(this.addForm, 'data7', this.addForm.longitude + "," + this.addForm.latitude);
        this.$refs.mapPosition.setMap1(row.longitude, row.latitude);
      }, 30);
      this.addForm = JSON.parse(JSON.stringify(row));
    },
    // 删除
    deletes(id) {
      // 弹出提示是否删除
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.$post("/villagerInformation/delVillagerInformationById", {
              id: id,
            }).then((res) => {
              if (res.data.state == "success") {
                this.$message.success("删除成功");
                this.getList();
              } else {
                this.$message.error("删除失败");
              }
            });
          })
          .catch((e) => e);
    },
    // 获取地图组件数据
    getDatas(e) {
      this.addForm.data7 = e;
      this.data7 = e;
      this.addForm.longitude = e.split(",")[0];
      this.addForm.latitude = e.split(",")[1];
      this.$forceUpdate();
    },
    // 确认提交
    saveForm(formName) {
      this.addForm.id = this.ids;
      this.addForm.longitude = this.addForm.data7.split(",")[0];
      this.addForm.latitude = this.addForm.data7.split(",")[1];
      let url = '/villagerInformation/addOrUpdateVillagerInformation';
      if(this.formTitle == '新增'){
        delete this.addForm.id;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post(url, this.addForm
          ).then((res) => {
            if (res.data.state == 'success') {
              if(this.formTitle == '新增'){
                this.$message.success('添加成功')
              }else{
                this.$message.success('修改成功')
              }
              this.messageBox = false
              this.getList();
            } else {
              this.$message.error(res.data.msg)
              this.messageBox = false
            }
          })
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },
    // 新增
    addOption() {
      this.formTitle = "新增";
      this.messageBox = true;
      this.showFlag = true;
      setTimeout(() => {
        this.$refs["addForm"].clearValidate();
        this.$refs.mapPosition.setMap1(119.69,31.34);
      }, 30);
      this.data7 = "";
      this.$nextTick(() => {
        this.addForm = {};
        this.$refs.addForm.resetFields();
      });
    },
    beforeAvatarUpload2(val) {
      const fileLimitSize = 2;// 几兆限制
      const isJPG = val.type === "image/jpeg";
      const isJPGs = val.type === "image/png";
      const fileLimit = val.size / 1024 / 1024 < fileLimitSize;
      if (!isJPG && !isJPGs) {
        this.$message.info("上传图片格式只能为 JPG 或 PNG 格式！");
        this.fileList2 = [];
        return false
      }
      if (!fileLimit) {
        this.$message.info(`上传图片大小不能超过${fileLimitSize}MB!`);
        this.fileList2 = [];
        return false
      }

      return true;
    },
    handleAvatarSuccess2(res, file) {
      this.addForm.pic =
          "https://public.half-half.cn/" + res.data.fileName;
      this.addForm = JSON.parse(JSON.stringify(this.addForm));
    },
    search() {
      //console.log(11111)
      this.page = 1
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../style/backTable.less";
</style>
<style scoped>
#content /deep/ .el-table th {
  background-color: rgba(240, 242, 245, 1) !important;
}

#content /deep/ .el-table {
  max-height: 78vh;
  overflow-y: scroll;
}

#content /deep/ .el-table::-webkit-scrollbar {
  display: none;
}

.addAlaForm /deep/ .el-select {
  width: 100% !important;
}

.dialog-footer {
  height: 5vh;
  text-align: right;
}

.dialog-footer /deep/ .el-button, .el-button--info {
  margin-left: 10px !important;

}

.dialog-footer /deep/ .el-button--info {
  background-color: transparent !important;
  color: #000;
}

.title /deep/ .el-input__inner {
  height: 33px !important;
}

.drawerBox /deep/ .msg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1vh 1vw;
}
.isUpload /deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 12vh;
  height: 12vh;
}
.isUpload /deep/.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.isUpload /deep/.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 12vh;
  height: 12vh;
  line-height: 12vh;
  text-align: center;
}
.isUpload .businessImg {
  width: 12vh;
  height: 12vh;
}
.imgs{
  width: 6vh;
  height: 6vh;
}

</style>


